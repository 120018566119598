// Generated by Framer (575e68f)

import { addFonts, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["GUGSAh_Ir"];

const serializationHash = "framer-E12PL"

const variantClassNames = {GUGSAh_Ir: "framer-v-1fyw7i7"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "GUGSAh_Ir", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.button {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1fyw7i7", className, classNames)} data-framer-name={"Variant 1"} data-reset={"button"} layoutDependency={layoutDependency} layoutId={"GUGSAh_Ir"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-88305b37-a160-4ada-bd3d-0da90dbd7567, rgb(238, 63, 42))", borderBottomLeftRadius: 31, borderBottomRightRadius: 31, borderTopLeftRadius: 31, borderTopRightRadius: 31, boxShadow: "0px 0.7065919983928324px 0.7065919983928324px -0.625px rgba(0, 0, 0, 0.14764), 0px 1.8065619053231785px 1.8065619053231785px -1.25px rgba(0, 0, 0, 0.14398), 0px 3.6217592146567767px 3.6217592146567767px -1.875px rgba(0, 0, 0, 0.13793), 0px 6.8655999097303715px 6.8655999097303715px -2.5px rgba(0, 0, 0, 0.12711), 0px 13.646761411524492px 13.646761411524492px -3.125px rgba(0, 0, 0, 0.10451), 0px 30px 30px -3.75px rgba(0, 0, 0, 0.05)", ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7SW50ZXItODAw", "--framer-font-family": "\"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "20px", "--framer-font-weight": "800", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-88305b37-a160-4ada-bd3d-0da90dbd7567, rgb(238, 63, 42)))"}}>x</motion.p></React.Fragment>} className={"framer-4zefce"} fonts={["GF;Inter-800"]} layoutDependency={layoutDependency} layoutId={"VA7b4hRda"} style={{"--extracted-r6o4lv": "var(--token-88305b37-a160-4ada-bd3d-0da90dbd7567, rgb(238, 63, 42))", "--framer-paragraph-spacing": "0px"}} verticalAlignment={"top"} withExternalLayout/></motion.button></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-E12PL.framer-5h7e58, .framer-E12PL .framer-5h7e58 { display: block; }", ".framer-E12PL.framer-1fyw7i7 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 18px 20px 18px 20px; position: relative; width: min-content; }", ".framer-E12PL .framer-4zefce { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-E12PL.framer-1fyw7i7 { gap: 0px; } .framer-E12PL.framer-1fyw7i7 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-E12PL.framer-1fyw7i7 > :first-child { margin-left: 0px; } .framer-E12PL.framer-1fyw7i7 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 60
 * @framerIntrinsicWidth 52
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerpragAv3lE: React.ComponentType<Props> = withCSS(Component, css, "framer-E12PL") as typeof Component;
export default FramerpragAv3lE;

FramerpragAv3lE.displayName = "CTA main color";

FramerpragAv3lE.defaultProps = {height: 60, width: 52};

addFonts(FramerpragAv3lE, [{explicitInter: true, fonts: [{family: "Inter", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/inter/v18/UcCO3FwrK3iLTeHuS_nVMrMxCp50SjIw2boKoduKmMEVuDyYMZ1rib2Bg-4.woff2", weight: "800"}]}], {supportsExplicitInterCodegen: true})